// import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import'../styles/header.css'

// export default function Header() {
//     return (
//         <div className="header-container">
//             <Navbar bg="dark" data-bs-theme="dark">
//                 <Container>
//                     <Navbar.Brand href="#home">Cameron McRae</Navbar.Brand>
//                     <Nav className="me-auto">
//                         <Nav.Link href="#about">About</Nav.Link>
//                         <Nav.Link href="#projects">Projects</Nav.Link>
//                         <Nav.Link href="#skills">Skills</Nav.Link>
//                         <Nav.Link href="#contact">Contact</Nav.Link>
//                     </Nav>
//                     <Button>Contact</Button>
//                 </Container>
//             </Navbar>
//         </div>
//     );
//
// }
function Header() {
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary header-container">
            <Container>
                <Navbar.Brand href="#home">Cameron McRae</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#projects">Projects</Nav.Link>
                        <Nav.Link href="#skills">Skills</Nav.Link>
                    </Nav>
                    <Nav>

                        <Nav.Link href="#contact">
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;